<template>
  <div class="manage-project-mortgage-bank-loan-detail">
    <div class="container fluid">
      <bank-loan-detail
        :canManage="false"
        :downloadFileAPI="downloadFile"
        :getBankLoanAPI="mortgageAPI.getBankLoan"
        :verifyLeadAPI="mortgageAPI.verifyLead"
        :declineLeadAPI="mortgageAPI.declineLead"
        :approveLAAPI="mortgageAPI.approveLA"
        :rejectLAAPI="mortgageAPI.rejectLA"
        :confirmLOSignedAPI="mortgageAPI.confirmLOSigned"
        listingRouteName="ManageProjectMortgageBankLoan"
      ></bank-loan-detail>
    </div>
  </div>
</template>

<script>
import { projectMortgage as mortgageAPI } from "@/api";
import BankLoanDetail from "@/components/GlobalComponents/Shared/Banker/BankLoanDetail";
import { downloadFile } from "@/utils/download";

export default {
  components: {
    BankLoanDetail
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      mortgageAPI,
      downloadFile
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
